import React, { Component } from 'react';
import Buttons from './components/Buttons';
import './App.css';

class App extends Component {
  render(){

    return (
    <div className="App">
     <Buttons />
    </div>
  );
}
}


export default App;
