import React, { Component } from 'react';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
class Buttons extends Component {
    constructor(props) {
        super(props);

       this.state = {
           sales: [],
           prices: ['0.00','0.00','0.00','0.00','0.00','0.00','0.00','0.00','0.00','0.00','0.00','0.00'],
           cardCountLimit: [0,0,0,0,0,0,0,0,0,0,0,0],
           editisactive: false,
           isactive: false,
           tab1: true,
           tab2: false,
           total: 0,
           one: 0,
           two: 0,
           three: 0,
           four: 0,
           five: 0,
           six: 0,
           seven: 0,
           eight: 0,
           nine: 0,
           ten: 0,
           eleven: 0,
           twelve: 0,
           lastclickedgroup: [],
           lastclicked: 0,
           countone: 0,
           counttwo: 0,
           countthree: 0,
           countfour: 0,
           countfive: 0,
           countsix: 0,
           countseven: 0,
           counteight: 0,
           countnine: 0,
           countten: 0,
           counteleven: 0,
           counttwelve: 0
          }   

        this.purchase = this.purchase.bind(this);
        this.displayTotal = this.displayTotal.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.startNewDay = this.startNewDay.bind(this);
        this.undo = this.undo.bind(this);
        this.editPricesModal = this.editPricesModal.bind(this);
        this.configTabs = this.configTabs.bind(this);
            
    }
    
    

    componentDidMount() {
        const localStoredState = JSON.parse(localStorage.getItem('state'));
        this.setState(localStoredState);
    }

    componentDidUpdate() {
      if(this.state.countone > this.state.cardCountLimit[0]) {
        this.setState({countone: 0})
      }
      if(this.state.countone < 0) {
        this.setState({countone: this.state.cardCountLimit[0]})
      }

      if(this.state.counttwo > this.state.cardCountLimit[1]) {
        this.setState({counttwo: 0})
      }
      if(this.state.counttwo < 0) {
        this.setState({counttwo: this.state.cardCountLimit[1]})
      }

      if(this.state.countthree > this.state.cardCountLimit[2]) {
        this.setState({countthree: 0})
      }
      if(this.state.countthree < 0) {
        this.setState({countthree: this.state.cardCountLimit[2]})
      }

      if(this.state.countfour > this.state.cardCountLimit[3]) {
        this.setState({countfour: 0})
      }
      if(this.state.countfour < 0) {
        this.setState({countfour: this.state.cardCountLimit[3]})
      }

      if(this.state.countfive > this.state.cardCountLimit[4]) {
        this.setState({countfive: 0})
      }
      if(this.state.countfive < 0) {
        this.setState({countfive: this.state.cardCountLimit[4]})
      }

      if(this.state.countsix > this.state.cardCountLimit[5]) {
        this.setState({countsix: 0})
      }
      if(this.state.countsix < 0) {
        this.setState({countsix: this.state.cardCountLimit[5]})
      }

      if(this.state.countseven > this.state.cardCountLimit[6]) {
        this.setState({countseven: 0})
      }
      if(this.state.countseven < 0) {
        this.setState({countseven: this.state.cardCountLimit[6]})
      }

      if(this.state.counteight > this.state.cardCountLimit[7]) {
        this.setState({counteight: 0})
      }
      if(this.state.counteight < 0) {
        this.setState({counteight: this.state.cardCountLimit[7]})
      }

      if(this.state.countnine > this.state.cardCountLimit[8]) {
        this.setState({countnine: 0})
      }
      if(this.state.countnine < 0) {
        this.setState({countnine: this.state.cardCountLimit[8]})
      }

      if(this.state.countten > this.state.cardCountLimit[9]) {
        this.setState({countten: 0})
      }
      if(this.state.countten < 0) {
        this.setState({countten: this.state.cardCountLimit[9]})
      }

      if(this.state.counteleven > this.state.cardCountLimit[10]) {
        this.setState({counteleven: 0})
      }
      if(this.state.counteleven < 0) {
        this.setState({counteleven: this.state.cardCountLimit[10]})
      }

      if(this.state.counttwelve > this.state.cardCountLimit[11]) {
        this.setState({counttwelve: 0})
      }
      
      if(this.state.counttwelve < 0) {
        this.setState({counttwelve: this.state.cardCountLimit[11]})
      }
    }
    
    
    updateState = (newState) => {
        this.setState(newState, () => {
          localStorage.setItem('state', JSON.stringify(this.state));
        });
      }

    displayTotal(){
        this.updateState ({isactive: true});
    }

    editPricesModal(){
        this.setState ({editisactive: true})
        console.log(this.state.editisactive, 'this is edit state ');
    }

    closeModal() {
        this.updateState ({isactive: false});
        this.setState({editisactive: false});
 }

    configTabs(e) {
      console.log(e.target.classList[0])
      switch(e.target.classList[0]){
        case 'tab1':
          this.setState({tab1: true});
          this.setState({tab2: false});
          break;
        case 'tab2':
          this.setState({tab1: false});
          this.setState({tab2: true});
          break;
        }
        
    }

    handlePriceChange(event, index) {
      const currentPrices = this.state.prices;

      currentPrices[index] = event.target.value;

      this.updateState({prices: currentPrices});
    }

    handleCardCountChange(event, index) {
      const currentCountLimit = this.state.cardCountLimit;

      currentCountLimit[index] = event.target.value;

      this.updateState({cardCountLimit: currentCountLimit});
    }

    calculateTotal() {
        const stringTotal = this.state.sales.toString();

       
            const totals = stringTotal.split`,`.map(x => +x);

            const finalTotal = totals.reduce(function (a, b) {
                return a + b;
            }, 0);
    
            return finalTotal;
    }

    startNewDay(){
        this.updateState ({one: 0, two: 0, three: 0, four: 0, five: 0, six: 0, seven: 0, eight: 0, nine: 0, ten: 0, eleven: 0, twelve: 0, sales: [], total: 0, lastclickedgroup: [], lastclicked: 0})

    }

    undo(){
        const lastClickedGroup = [...this.state.lastclickedgroup];
        const undoSales = [...this.state.sales];
        undoSales.pop();
        lastClickedGroup.pop();

        const lastClicked = lastClickedGroup[lastClickedGroup.length - 1];

        

        this.updateState ({sales: undoSales, total: this.state.total - 1, lastclicked: lastClicked, lastclickedgroup: lastClickedGroup});

        if(this.state.total < 0) { 
          this.updateState({total: 0})
        }
        
        switch(this.state.lastclicked) {
            case 1:
                this.updateState ({one: this.state.one - 1, countone: this.state.countone - 1})
              break;
            case 2:
                this.updateState ({two: this.state.two - 1, counttwo: this.state.counttwo - 1 })
              break;
            case 3:
                this.updateState ({three: this.state.three - 1, countthree: this.state.countthree - 1})
              break;
            case 4:
                this.updateState ({four: this.state.four - 1, countfour: this.state.countfour - 1 })
              break;
            case 5:
                this.updateState ({five: this.state.five - 1, countfive: this.state.countfive - 1})
              break;
            case 6:
                this.updateState ({six: this.state.six - 1, countsix: this.state.countsix - 1})
              break;
            case 7:
                this.updateState ({seven: this.state.seven - 1, countseven: this.state.countseven - 1})
              break;
            case 8:
                this.updateState ({eight: this.state.eight - 1, counteight: this.state.counteight - 1})
              break;
            case 9:
                this.updateState ({nine: this.state.nine - 1, countnine: this.state.countnine - 1})
              break;
            case 10:
                this.updateState ({ten: this.state.ten - 1, countten: this.state.countten - 1})
              break;
            case 11:
                this.updateState ({eleven: this.state.eleven - 1, counteleven: this.state.counteleven - 1})
              break;
            case 12:
                this.updateState ({twelve: this.state.twelve - 1, counttwelve: this.state.counttwelve - 1})
              break;
            default:
              
          }
    }
  
  purchase(price,number){
    
    const updatedTotal = [...this.state.sales, price];
    //console.log(updatedTotal);
    this.updateState ({sales: updatedTotal, total: this.state.total + 1});
    switch(number) {
        case 1:
            this.updateState ({one: this.state.one + 1, lastclickedgroup: [...this.state.lastclickedgroup, 1], lastclicked: 1, countone: this.state.countone + 1 })
          break;
        case 2:
            this.updateState ({two: this.state.two + 1, lastclickedgroup: [...this.state.lastclickedgroup, 2], lastclicked: 2, counttwo: this.state.counttwo + 1})
          break;
        case 3:
            this.updateState ({three: this.state.three + 1, lastclickedgroup: [...this.state.lastclickedgroup, 3], lastclicked: 3, countthree: this.state.countthree + 1})
          break;
        case 4:
            this.updateState ({four: this.state.four + 1, lastclickedgroup: [...this.state.lastclickedgroup, 4], lastclicked: 4, countfour: this.state.countfour + 1})
          break;
        case 5:
            this.updateState ({five: this.state.five + 1, lastclickedgroup: [...this.state.lastclickedgroup, 5], lastclicked: 5, countfive: this.state.countfive + 1})
          break;
        case 6:
            this.updateState ({six: this.state.six + 1, lastclickedgroup: [...this.state.lastclickedgroup, 6], lastclicked: 6, countsix: this.state.countsix + 1})
          break;
        case 7:
            this.updateState ({seven: this.state.seven + 1, lastclickedgroup: [...this.state.lastclickedgroup, 7], lastclicked: 7, countseven: this.state.countseven + 1})
          break;
        case 8:
            this.updateState ({eight: this.state.eight + 1, lastclickedgroup: [...this.state.lastclickedgroup, 8], lastclicked: 8, counteight: this.state.counteight + 1})
          break;
        case 9:
            this.updateState ({nine: this.state.nine + 1, lastclickedgroup: [...this.state.lastclickedgroup, 9], lastclicked: 9, countnine: this.state.countnine + 1})
          break;
        case 10:
            this.updateState ({ten: this.state.ten + 1, lastclickedgroup: [...this.state.lastclickedgroup, 10], lastclicked: 10, countten: this.state.countten + 1})
          break;
        case 11:
            this.updateState ({eleven: this.state.eleven + 1, lastclickedgroup: [...this.state.lastclickedgroup, 11], lastclicked: 11, counteleven: this.state.counteleven + 1})
          break;
        case 12:
            this.updateState ({twelve: this.state.twelve + 1, lastclickedgroup: [...this.state.lastclickedgroup, 12], lastclicked: 12, counttwelve: this.state.counttwelve + 1})
          break;
        default:
          
      }
    }

    

  render(){
    const pricesArray = [0.00, 1.00, 2.00, 3.00, 4.00, 5.00, 6.00, 7.00, 8.00, 9.00, 10.00];
    const countValues = Array.from(Array(200).keys());
    this.cards = [
      {
          title: 1,
          price: this.state.prices[0] === '' ? 0 : this.state.prices[0],
          total: 'one',
          count: this.state.countone,
         
      },
      {
          title: 2,
          price: this.state.prices[1] === '' ? 0 : this.state.prices[1],
          total: 'two',
          count: this.state.counttwo,
          
      },
      {
          title: 3,
          price: this.state.prices[2] === '' ? 0 : this.state.prices[2],
          total: 'three',
          count: this.state.countthree,
          
      },
      {
          title: 4,
          price: this.state.prices[3] === '' ? 0 : this.state.prices[3],
          total: 'four',
          count: this.state.countfour,
         
      },
      {
          title: 5,
          price: this.state.prices[4] === '' ? 0 : this.state.prices[4],
          total: 'five',
          count: this.state.countfive,
          
      },
      {
          title: 6,
          price: this.state.prices[5] === '' ? 0 : this.state.prices[5],
          total: 'six',
          count: this.state.countsix,
          
      },
      {
          title: 7,
          price: this.state.prices[6] === '' ? 0 : this.state.prices[6],
          total: 'seven',
          count: this.state.countseven,
         
      },
      {
          title: 8,
          price: this.state.prices[7] === '' ? 0 : this.state.prices[7],
          total: 'eight',
          count: this.state.counteight,
          
      },
      {
          title: 9,
          price: this.state.prices[8] === '' ? 0 : this.state.prices[8],
          total: 'nine',
          count: this.state.countnine,
         
      },
      {
          title: 10,
          price: this.state.prices[9] === '' ? 0 : this.state.prices[9],
          total: 'ten',
          count: this.state.countten,
         
      },
      {
          title: 11,
          price: this.state.prices[10] === '' ? 0 : this.state.prices[10],
          total: 'eleven',
          count: this.state.counteleven,
          
      },
      {
          title: 12,
          price: this.state.prices[11] === '' ? 0 : this.state.prices[11],
          total: 'twelve',
          count: this.state.counttwelve,
         
      }

     ]

   
    return (
    <div className="scratch-app">
    <div className="scratch-numbers">
        {this.cards.map((card, index) => (
                <div className="number" key={index} onClick={() => this.purchase(card.price,card.title)}>
                    <span>{card.title}</span>
                    <div className="container">
                    <div className="card-count">
                    Card Count: {String(card.count).padStart(3, '0')}
                    </div>
                    <div className="card-price">Card Price: {card.price}</div>
                    </div>
                   
                    <div className="indiv-total">
                      Total Sold: {this.state[card.total]}
                    </div>
                    </div>
                ))}
    </div>
    
    <div className="buttons">
    <button className="undo" onClick={this.undo}>Undo</button>
    <button className="new_day" onClick={this.startNewDay}>Start New Day</button>
    <button className="total" onClick={this.displayTotal}>Daily Total</button>
    <button className="edit_prices" onClick={this.editPricesModal}>Settings</button>
    </div>

    <div className={`modal-outer ${this.state.editisactive ? 'open' : ''}`}>
    <div className="modal-inner">
      
        <button href="#" onClick={this.closeModal} className="close">&times;</button>
        <div className="tabs">
        <button className={`tab1 ${this.state.tab1 ? 'active' : ''}`} onClick={this.configTabs}>Edit Price</button>
        <button className={`tab2 ${this.state.tab2 ? 'active' : ''}`} onClick={this.configTabs}>Edit Count Limit</button>
      </div>
      {this.state.tab1 && (
         <div className="prices-input-outside">
         {this.cards.map((card, index) => {
           return <>
           <div className="prices-input">
           <InputLabel id="select-label" key={`input-${index}`}>{card.title}</InputLabel>
           <Select
             labelId="select-label"
             defaultValue={0.00}
             id={`select-${index}`}
             value={card.price}
             label={card.title}
             onChange={value => this.handlePriceChange(value, index)}
           >
             {pricesArray.map((price, index) => {
               return <MenuItem value={`${index}.00`}>{`${index}.00`}</MenuItem>
             })}
           </Select>
           </div>
           </>     
          })}
         </div>
      )}
      {this.state.tab2 && (
        <div className="count-limit">
           {this.state.prices.map((price, index) => {
           return <>
           <div className="prices-input">
           <InputLabel id="select-label" key={`input-${index}`}>{price}</InputLabel>
           <Select
             labelId="select-label"
             defaultValue={0}
             id={`select-${index}`}
             value={this.state.cardCountLimit[index]}
             label={price}
             onChange={value => this.handleCardCountChange(value, index)}
           >
            {countValues.map((value) => {
              return <MenuItem value={value}>{value}</MenuItem>
            })}
           </Select>
           </div>
           </>     
          })}
        </div>
      )}
     
       
    </div>
    </div>

    <div className={`modal-outer ${this.state.isactive ? 'open' : ''}`}>
    <div className="modal-inner">
        <button href="#" onClick={this.closeModal} className="close">&times;</button>
        <div className="text-outer">
            <div className="pop-text">Todays takings are £{this.calculateTotal()}</div>
            <div className="quantity-sold">You have sold {this.state.total} scratch cards today</div>
        </div>

    </div>
    </div>

    </div>
   
  );
}
}


export default Buttons;

